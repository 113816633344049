import _ from 'lodash';
import {PubSub as PS} from 'pubsub-js';
import createHistory from 'history/createBrowserHistory';
import * as log from 'loglevel';
import 'cross-fetch/polyfill';

let queuedScripts = [];
let loadedScripts = [];

const myStorage = window.localStorage;

if(myStorage.getItem('ISTRACKDEBUG')){
  log.setLevel("trace");
}else{
  log.setLevel("silent");
}

const _d = log.debug;

export default function ISTRACK(){
  _d('%cSTOP!', 'color: red; font-size: 45px; font-weight:bold;-webkit-text-stroke-width: 1px;-webkit-text-stroke-color: black;', 'Pero syempre joke lang! Istracker Debugging on...')
  let ISTAG = '_istag';
  const ISTAGLAYER = '_istagLayer';
  window[ISTAGLAYER] = window[ISTAGLAYER] || {push: override};
  if(Array.isArray(window[ISTAGLAYER]) && window[ISTAGLAYER].length){
    
    window[ISTAGLAYER].forEach(layer => {
      override(layer);
    });
  }

  window[ISTAGLAYER] = {push: override};;
};

function loadScript(src, callback) {
  let script = document.createElement('script'),
      loaded;
  script.setAttribute('src', `${src}?t=${Date.now()}`);
  script.async = 1;
  if (callback) {
    script.onreadystatechange = script.onload = function() {
      if (!loaded) {
        callback();
      }
      loaded = true;
    };
  }
  document.getElementsByTagName('head')[0].appendChild(script);
}

function override(layer){
  let [event, payload] = layer;

  if(event === 'plugin'){
    // do not load if its already loaded or on queued
    if(loadedScripts.includes(payload.id) || queuedScripts.includes(payload.id))return;
    queuedScripts.push(payload.id);
    loadScript(payload.src, ()=>{
      _.remove(queuedScripts, (id)=>id==payload.id);
      loadedScripts.push(payload.id);
      payload.callback && payload.callback();
      PS.publish(`${payload.id}_ONLOAD`);
    });
  }else{
    //do this incase it has id that is dependent to a loaded script. eg: tools.init where 'tools' is the id
    let eventId = _.split(event,'.')[0];
    if(queuedScripts.includes(eventId)){
      _d('queuedScripts', event)
      PS.subscribe(`${eventId}_ONLOAD`,()=>{
        PS.publish(event, {payload, utils: {_, fetch, createHistory, debug: _d}});
      });
    }else{
      _d('elsequeuedScripts', event)
      PS.publish(event, {payload, utils: {_, fetch, createHistory, debug: _d}});
    }
  }
}

ISTRACK();